<template>
  <b-card-code title="Ajouter un client">
    <b-card-text>
      <span>Coordonnées du client</span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Client"
            >
              <validation-provider
                #default="{ errors }"
                name="company_name"
              >
                <b-form-input
                  v-model="customer.company_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Client"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
              >
                <b-form-input
                  id="email"
                  v-model="customer.email"
                  placeholder="email"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="téléphone"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
              >
                <b-form-input
                  v-model="customer.phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="téléphone"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="adresse"
            >
              <validation-provider
                #default="{ errors }"
                name="address"
              >
                <b-form-input
                  v-model="customer.address"
                  :state="errors.length > 0 ? false : null"
                  placeholder="adresse"
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Matricule fiscale"
            >
              <validation-provider
                #default="{ errors }"
                name="tax_identification_number"
              >
                <b-form-input
                  v-model="customer.tax_identification_number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Matricule fiscale"
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="cin"
            >
              <validation-provider
                #default="{ errors }"
                name="cin"
              >
                <b-form-input
                  v-model="customer.cin"
                  :state="errors.length > 0 ? false : null"
                  placeholder="cin"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="zone"
              label-for="state"
            >
              <validation-provider
                #default="{ errors }"
                name="state"
              >
                <v-select
                  v-model="customer.state"
                  :options="states"
                  :clearable="false"
                  :reduce="state => state.id"
                  label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addCustomer"
            >
              Enregistrer
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      customer: {
        company_name: '',
        email: '',
        phone: '',
        address: '',
        tax_identification_number: '',
        cin: '',
        state: null,
      },
      required,
    }
  },
  computed: {
    ...mapGetters('governorate', ['states']),
  },
  created() {
    this.getStates()
  },
  methods: {
    ...mapActions('governorate', ['getStates']),
    async addCustomer() {
      try {
        await axiosIns.post('/customers/create/', this.customer)
        setTimeout(() => {
          this.showToast('success', 'top-center', 'Client ajouté avec succès')
        }, 1000)
        this.$router.push('/customers/')
      } catch (error) {
        setTimeout(() => {
          this.showToast('danger', 'top-center', error.toString())
        }, 1000)
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>
